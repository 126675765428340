<template>
  <v-main class="grow d-flex flex-column flex-nowrap fill-height">
    <app-public-header :showHome="true" :showBack="false" />
    <div :class="$vuetify.breakpoint.smAndDown ? 'd-flex ma-4 mt-0' : 'd-flex mx-4 my-0'">
      <h2 :class="$vuetify.breakpoint.smAndDown ? 'mr-2 mt-2' : ''">Diagnostic Information</h2>
      <v-spacer />
    </div>

    <div :class="$vuetify.breakpoint.smAndDown ? 'ma-4' : 'mx-4'">
      <v-btn :block="$vuetify.breakpoint.smAndDown" small color="primary" class="mr-2 mt-2" @click="loadFile()">
        Load Archive
      </v-btn>
      <v-btn
        :block="$vuetify.breakpoint.smAndDown"
        small
        outlined
        color="primary"
        class="mr-2 mt-2"
        @click="loadSession()"
      >
        Reload Current Session
      </v-btn>
      <v-btn
        :block="$vuetify.breakpoint.smAndDown"
        small
        outlined
        color="success"
        class="mr-2 mt-2"
        @click="exportSession()"
      >
        Export Archive
      </v-btn>
      <v-btn
        :block="$vuetify.breakpoint.smAndDown"
        small
        color="error"
        class="mr-2 mt-2"
        @click="dialogClearSession = true"
      >
        Clear Current Session Log
      </v-btn>

      <v-switch
        :disabled="loadedFile != null"
        v-model="followSession"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'mt-0 pt-0 d-inline-block'"
        label="Follow Session"
        @change="followSessionChanged()"
      />
      <input ref="fileUpload" type="file" hidden accept=".zip" @change="fileLoaded()" />
    </div>

    <v-system-bar window dark class="shrink">
      <label class="text-h6 px-3">{{ loadedFileName }}</label>
      <v-spacer />
    </v-system-bar>

    <v-row class="vh-100">
      <v-tabs
        v-model="tab"
        :horizontal="$vuetify.breakpoint.smAndDown"
        :vertical="!$vuetify.breakpoint.smAndDown"
        background-color="grey darken-3"
        :icons-and-text="$vuetify.breakpoint.smAndDown"
        :grow="$vuetify.breakpoint.smAndDown"
      >
        <v-tab key="trace" :class="$vuetify.breakpoint.smAndDown ? '' : 'justify-start'">
          <v-icon :left="!$vuetify.breakpoint.smAndDown"> mdi-text-box-outline </v-icon>
          Trace
        </v-tab>
        <v-tab key="tokens" :class="$vuetify.breakpoint.smAndDown ? '' : 'justify-start'">
          <v-icon :left="!$vuetify.breakpoint.smAndDown"> mdi-account-key-outline </v-icon>
          Tokens
        </v-tab>
        <v-tab key="vuex" :class="$vuetify.breakpoint.smAndDown ? '' : 'justify-start'">
          <v-icon :left="!$vuetify.breakpoint.smAndDown"> mdi-cog-outline </v-icon>
          VueX
        </v-tab>
        <v-tab key="fingerprint" :class="$vuetify.breakpoint.smAndDown ? '' : 'justify-start'">
          <v-icon :left="!$vuetify.breakpoint.smAndDown"> mdi-fingerprint </v-icon>
          Fingerprint
        </v-tab>
        <v-tab key="debug" :class="$vuetify.breakpoint.smAndDown ? '' : 'justify-start'">
          <v-icon :left="!$vuetify.breakpoint.smAndDown"> mdi-tools </v-icon>
          Debug
        </v-tab>

        <v-tabs-items v-model="tab" class="px-4">
          <v-tab-item key="trace" :transition="false">
            <v-data-table
              :headers="traceHeaders"
              :items="parsedData.trace"
              :footer-props="{
                'items-per-page-options': [50, 100, -1],
              }"
              :items-per-page="50"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :search="search"
              must-sort
              show-expand
              single-expand
              item-key="uuid"
              @click:row="(item, slot) => item.data != null && slot.expand(!slot.isExpanded)"
            >
              <template v-slot:top="{ pagination, options, updateOptions }">
                <v-row>
                  <v-col cols="12" sm="4" class="pa-0 px-2">
                    <v-text-field v-model="search" label="Search" class="mx-4"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8" class="pa-0" :class="$vuetify.breakpoint.smAndDown ? 'px-0' : 'px-2'">
                    <v-data-footer
                      style="border: none"
                      :pagination="pagination"
                      :options="options"
                      @update:options="updateOptions"
                      items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                      :items-per-page-options="[50, 100, -1]"
                    />
                  </v-col>
                </v-row>
              </template>
              <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
                <v-icon v-if="item.data != null && !isExpanded">mdi-chevron-down</v-icon>
                <v-icon v-if="item.data != null && isExpanded">mdi-chevron-up</v-icon>
              </template>
              <template v-slot:[`item.timestamp`]="{ item }">
                {{ item.timestamp | localtimefilter }}
              </template>
              <template v-slot:[`item.message`]="{ item }">
                <div :style="$vuetify.breakpoint.smAndDown ? 'max-width: 280px; overflow-wrap: break-word' : ''">
                  {{ item.message }}
                </div>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <vue-code-highlight language="json">
                    <pre>{{ item.data }}</pre>
                  </vue-code-highlight>
                </td>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item key="tokens" class="pa-4" :transition="false">
            <v-row align="center">
              <v-col cols="6">
                <h4>Access Token</h4>
              </v-col>
              <v-col cols="6" align="right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      text
                      color="primary"
                      @click="copyTextToClipboard(data.accessToken, on, $event)"
                    >
                      <v-icon left> mdi-content-copy </v-icon>
                      Copy RAW Encoded
                    </v-btn>
                  </template>
                  <span>Copied!</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <vue-code-highlight language="json" v-if="forceRerender">
              <pre>{{ parsedData.accessToken }}</pre>
            </vue-code-highlight>
            <v-row align="center">
              <v-col cols="6">
                <h4>Refresh Token</h4>
              </v-col>
              <v-col cols="6" align="right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      text
                      color="primary"
                      @click="copyTextToClipboard(data.refreshToken, on, $event)"
                    >
                      <v-icon left> mdi-content-copy </v-icon>
                      Copy RAW Encoded
                    </v-btn>
                  </template>
                  <span>Copied!</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <vue-code-highlight language="json" v-if="forceRerender">
              <pre>{{ parsedData.refreshToken }}</pre>
            </vue-code-highlight>
          </v-tab-item>
          <v-tab-item key="vuex" class="pa-4" :transition="false">
            <h4>VueX Settings Store</h4>
            <vue-code-highlight language="json" v-if="forceRerender">
              <pre>{{ parsedData.vuex }}</pre>
            </vue-code-highlight>
          </v-tab-item>
          <v-tab-item key="fingerprint" class="pa-4" :transition="false">
            <h4>Fingerprint</h4>
            <vue-code-highlight language="json" v-if="forceRerender">
              <pre>{{ parsedData.fingerprint }}</pre>
            </vue-code-highlight>
          </v-tab-item>
          <v-tab-item key="debug" class="pa-4" :transition="false">
            <h4>Debug Settings</h4>
            <v-switch
              v-model="showDevFAB"
              :class="$vuetify.breakpoint.smAndDown ? 'mt-2' : 'mt-0 pt-0 d-inline-block'"
              label="Show Export Diagnostics FAB"
              @change="devFABChanged()"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-row>
    <v-dialog
      v-model="dialogClearSession"
      maxWidth="500"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      overlay-opacity="0.5"
      scrollable
      @keydown.esc="dialogClearSession = false"
      @click:outside="dialogClearSession = false"
    >
      <v-card>
        <v-toolbar flat>
          <h2>Warning</h2>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogClearSession = false">
            <v-icon size="24">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-4"> Are you sure you want to clear the current session's trace log? </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-btn @click="dialogClearSession = false" color="primary">
            <v-icon left>mdi-cancel</v-icon>
            <span class="font-weight-bold">Cancel</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="clearSession()" color="error">
            <v-icon left>mdi-trash-can-outline</v-icon>
            <span class="font-weight-bold">Clear Session</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="dialogDevelopers"
      maxWidth="500"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      overlay-opacity="0.5"
      scrollable
      persistent
    >
      <v-card>
        <v-toolbar flat>
          <h2>Developer Area</h2>
        </v-toolbar>
        <v-card-text class="mt-4">
          This area is intended for use by development staff. If you've ended up here by mistake you can easily return
          home with the buttons below. If you know what you are doing, please proceed.
        </v-card-text>
        <v-card-actions class="px-6 pb-6">
          <v-btn to="/" color="primary">
            <v-icon left>mdi-home-outline</v-icon>
            <span class="font-weight-bold">Return Home</span>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="dialogDevelopers = false" color="error">
            <v-icon left>mdi-badge-account-alert-outline</v-icon>
            <span class="font-weight-bold">I know what I'm doing</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import AppPublicHeader from "@/components/AppPublicHeader";
import tracer from "@/diagnostics/tracer";
import diagnostics from "@/diagnostics";
import jwtDecode from "jwt-decode";
import { component as VueCodeHighlight } from "vue-code-highlight";
import "vue-code-highlight/themes/prism-tomorrow.css";
import "prism-es6/components/prism-json";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "diagnostics",

  metaInfo: {
    title: "Diagnostics",
  },
  components: {
    VueCodeHighlight,
    AppPublicHeader,
  },
  computed: {
    ...mapGetters("settings", ["settings"]),
  },

  data() {
    return {
      tab: "trace",
      traceHeaders: [
        {
          text: "",
          value: "data-table-expand",
        },
        {
          text: "Timestamp",
          value: "timestamp",
          width: "250px",
        },
        {
          text: "Level",
          value: "level",
          width: "100px",
        },
        {
          text: "Message",
          value: "message",
          sortable: false,
        },
      ],
      sortBy: "timestamp",
      sortDesc: true,
      search: "",
      data: {
        trace: null,
        accessToken: null,
        refreshToken: null,
        vuex: null,
        fingerprint: null,
      },
      parsedData: {
        trace: [],
        accessToken: null,
        refreshToken: null,
        vuex: null,
        fingerprint: null,
      },
      loadedFile: null,
      loadedFileName: "",
      followSession: false,
      followSessionInterval: null,
      copiedTooltipTimeout: null,
      forceRerender: true,
      dialogClearSession: false,
      dialogDevelopers: true,
      showDevFAB: null,
    };
  },
  filters: {
    localtimefilter: function (value) {
      return new Date(value).toLocaleString("en-US", {
        timeZoneName: "short",
      });
    },
  },
  methods: {
    ...mapActions("settings", ["setShowDevFAB"]),
    loadFile() {
      this.followSession = false;
      this.$refs.fileUpload.click();
    },
    async fileLoaded() {
      if (!this.$refs.fileUpload.value) {
        return;
      }

      this.loadedFile = this.$refs.fileUpload.files[0];
      this.loadedFileName = this.loadedFile.name;

      this.data = null;
      this.data = await diagnostics.importAsync(this.loadedFile);
      this.parseData();
    },
    async loadSession() {
      this.loadedFile = null;
      this.loadedFileName = "Current Session";
      this.loadedFile = this.$refs.fileUpload.value = null;

      this.data = await diagnostics.getAsync();
      this.parseData();
    },
    exportSession() {
      diagnostics.exportAsync();
    },
    clearSession() {
      tracer.clearSessionTrace();
      this.loadSession();
      this.dialogClearSession = false;
    },
    parseData() {
      try {
        this.parsedData.trace = JSON.parse(this.data.trace);

        if (this.data.accessToken) {
          this.parsedData.accessToken = jwtDecode(this.data.accessToken);
        } else {
          this.parsedData.accessToken = "NO TOKEN";
        }

        if (this.data.refreshToken) {
          this.parsedData.refreshToken = jwtDecode(this.data.refreshToken);
        } else {
          this.parsedData.refreshToken = "NO TOKEN";
        }

        this.parsedData.vuex = JSON.parse(this.data.vuex);

        this.parsedData.fingerprint = JSON.parse(this.data.fingerprint);
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      }

      this.forceRerender = false;
      this.$nextTick(() => {
        this.forceRerender = true;
      });
    },
    followSessionChanged() {
      if (this.followSession) {
        clearInterval(this.followSessionInterval);
        this.followSessionInterval = setInterval(() => {
          this.loadSession();
        }, 1000);
      } else {
        clearInterval(this.followSessionInterval);
      }
    },
    copyTextToClipboard(val, { mouseenter, mouseleave }, e) {
      clearTimeout(this.copiedTooltipTimeout);
      mouseenter(e);
      window.navigator.clipboard.writeText(val);
      this.copiedTooltipTimeout = setTimeout(() => {
        mouseleave(e);
      }, 1000);
    },
    devFABChanged() {
      this.setShowDevFAB(this.showDevFAB);
    },
  },
  mounted() {
    this.showDevFAB = this.settings.showDevFAB;
    this.loadSession();
  },
  beforeDestroy() {
    clearInterval(this.followSessionInterval);
    clearTimeout(this.copiedTooltipTimeout);
  },
};
</script>
